import React from "react";
import { connect } from "react-redux";
import { Container, Col } from "react-bootstrap";
import uuid from "react-uuid";
import "../TreeInfoCard/TreeInfoCard.css";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { treeInfoExists } from "../../utilities/treeInfoCard";
import { convertPH, convertSun } from "../../utilities/conversions";

class SiteConditions extends React.Component {
  renderPlantingArea = () => {
    try {
      const pa = this.props.treeDetail.siteConditions.plantingArea;
      if (pa) {
        return (
          <span className="tree-info-card-text">
            Recommended planting area: {pa}
          </span>
        );
      } else {
        throw new Error("no planting area value");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderPlantingArea: ${err}`
      );
      return null;
    }
  };

  renderWaterUse = () => {
    try {
      const wu = this.props.treeDetail.siteConditions.waterUse;
      if (wu) {
        return (
          <span className="tree-info-card-text">
            SelecTree Water Use Rating: {wu}
          </span>
        );
      } else {
        throw new Error("no water use value");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderWaterUse: ${err}`
      );
      return null;
    }
  };

  renderSunsetZones = () => {
    try {
      const sc = this.props.treeDetail.siteConditions;
      if (sc.sunsetZones && sc.sunsetZones.length) {
        return (
          <span className="tree-info-card-text">
            Sunset zones:{" "}
            {sc.sunsetZones.map((sz, i) => {
              return (
                <span key={uuid()}>
                  {sz}
                  {i < sc.sunsetZones.length - 1 ? ", " : null}
                </span>
              );
            })}
          </span>
        );
      } else {
        throw new Error("sunsetZones length is empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderSunsetZones: ${err}`
      );
      return null;
    }
  };

  renderUsdaZones = () => {
    try {
      const sc = this.props.treeDetail.siteConditions;
      if (sc.usdaZones.length) {
        return (
          <span className="tree-info-card-text">
            USDA zones: {sc.usdaZones.sort((a, b) => a - b).join(", ")}
          </span>
        );
      } else {
        throw new Error("usdaZone length is empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderSunsetZones: ${err}`
      );
      return null;
    }
  };

  renderSoilTexture = () => {
    try {
      const sc = this.props.treeDetail.siteConditions;
      if (sc.soilTextures.length) {
        return (
          <span className="tree-info-card-text">
            Soil texture:{" "}
            {sc.soilTextures.map((st, i) => {
              return (
                <span key={uuid()}>
                  {st}
                  {i < sc.soilTextures.length - 1 ? " or " : null}
                </span>
              );
            })}
          </span>
        );
      } else {
        throw new Error("soilTextures length is empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderSoilTextures: ${err}`
      );
      return null;
    }
  };

  renderSoilPh = () => {
    try {
      const sc = this.props.treeDetail.siteConditions;
      if (sc.soil_ph_low && sc.soil_ph_high) {
        return (
          <span className="tree-info-card-text">
            Soil pH: {convertPH(sc.soil_ph_low)} to {convertPH(sc.soil_ph_high)}
          </span>
        );
      } else {
        throw new Error("soil_ph values empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderSoilPh: ${err}`
      );
      return null;
    }
  };

  renderSaltTolerance = () => {
    try {
      const ss = this.props.treeDetail.siteConditions.saltTolerance;
      if (ss) {
        return (
          <span className="tree-info-card-text">Salt tolerance: {ss}</span>
        );
      } else {
        throw new Error("no water use value");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderSaltTolerance: ${err}`
      );
      return null;
    }
  };
  renderSchoolyard = () => {
    try {
      const schoolyard = this.props.treeDetail.siteConditions.schoolyard
        ? "Yes"
        : null;

      return schoolyard !== null ? (
        <React.Fragment>
          {schoolyard != null && (
            <span className="tree-info-card-text">
              Schoolyard tree: {schoolyard}
            </span>
          )}
        </React.Fragment>
      ) : null;
    } catch (err) {
      console.log(
        `components.TreeInfo.SiteConditions.renderSchoolyard: ${err}`
      );
      return null;
    }
  };

  renderShadeTolerance = () => {
    try {
      const sc = this.props.treeDetail.siteConditions;
      if (sc.shade_tolerant !== null || sc.shade_tolerant !== undefined) {
        return (
          <span className="tree-info-card-text">
            Shade tolerance: {sc.shade_tolerant ? "Yes" : "No"}
          </span>
        );
      } else {
        throw new Error("shadeTolerance value is empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.renderShadeTolerance: ${err}`
      );
      return null;
    }
  };

  checkRenderInfo = () => {
    try {
      return treeInfoExists(
        this.renderPlantingArea(),
        this.renderWaterUse(),
        this.renderSunsetZones(),
        this.renderUsdaZones(),
        this.renderSoilTexture(),
        this.renderSoilPh(),
        this.renderSaltTolerance(),
        this.renderSchoolyard()
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.SiteConditions.checkRenderInfo: ${err}`
      );
      return false;
    }
  };

  renderContainer = () => {
    try {
      return (
        <Container className="tree-info-card--site-condition">
          {this.renderPlantingArea()}
          {this.renderSchoolyard()}
          {this.renderWaterUse()}
          {this.renderSunsetZones()}
          {this.renderShadeTolerance()}
          {this.renderUsdaZones()}
          {this.renderSoilTexture()}
          {this.renderSoilPh()}
          {this.renderSaltTolerance()}
        </Container>
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.PestAndDiseaseInfo.renderContainer: ${err}`
      );
    }
  };

  render() {
    return this.checkRenderInfo() ? (
      <Col lg={6} xs={12} className="mb-4">
        <ResponsiveAccordion title="SITE CONDITIONS">
          {this.renderContainer()}
        </ResponsiveAccordion>
      </Col>
    ) : null;
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};

export default connect(mapStateToProps, {})(SiteConditions);
