// Labels
export const INVENTORY_CATEGORY_LABEL = "Commercial/Sale List";

// List types
export const TREE_LIST = "Curated List";
export const SAVED_SEARCH = "Saved Search";
export const INVENTORY = "Inventory";

//ListRoles
export const LIST_ADMIN = "Admin";
export const LIST_COLLABORATOR = "Collaborator";
export const LIST_FOLLOWER = "Follower";

//ToolTips

export const TREE_LIST_TOOLTIP = `This is a static list. You can manually add or remove species from this list.`;
export const SAVED_SEARCH_TOOLTIP = `This is a saved search list. It’s a list generated 
                              by the filters associated with your saved search.`;
export const INVENTORY_TOOLTIP = `This is an inventory list. It’s a list where you can
                           track how many of each tree you carry in stock.`;

//The number of different pages to display in the pagination.
export const PAGES_TO_DISPLAY = 4;

//Array holding the number of pages you can select for the results
export const NUMBER_OF_RESULTS = [30, 50, 100];
export const DEFAULT_SORT = 1;
export const DEFAULT_ACTIVE_PAGE = 1;

// need these arrays to help with type consistency when loading from sessionStorage

export const INT_DEFAULT_KEYS = [
  "minTreeHeight",
  "maxTreeHeight",
  "tree_shape",
  "leaf_form",
  "leaf_arrangement",
  "planting_area",
  //Boolean 0 or 1
  "utility_friendly",
];

export const DEFAULT_SEARCH_OPTIONS = {
  activePage: 1,
  searchTerm: "",
  savedSearch: "",
  resultsPerPage: NUMBER_OF_RESULTS[0],
  sort: 1,
  scroll_y: 0,
};

export const STRING_DEFAULT_KEYS = ["foliage_type", "region", "searchTerm"];
export const PAGE_TOP_DESKTOP = { top: 228, behavior: "smooth" };

export const DEFAULT_FILTER_OPTIONS = () => {
  return {
    minTreeHeight: 0,
    maxTreeHeight: 100,
    fall_color: [],
    ca_native: [],
    hi_native: [],
    tree_shape: 0,
    foliage_type: "",
    leaf_form: 0,
    leaf_arrangement: 0,
    flower_color: [],
    fruit_type: [],
    fragrance: [],
    usda_zones: [],
    sunset_climate_zone: [],
    deer_tolerance: [],
    salt_tolerance: [],
    water_use_rating: [],
    sun_exposure: [],
    planting_area: 0,
    utility_precautions: [],
    searchTerm: "",
    utility_friendly: [],
    schoolyard: [],
    climate_region: [],
    zip_code: [],
    shade_tolerant: [],
    climate_adapted: [],
    adapted_zip_code: [],
  };
};

export const DEFAULT_TREE_FIELDS = () => {
  return {
    display: "0",
    pacific_island: 0,

    common_name: "",
    other_common_name: [],
    other_displayed_common_name: [],
    family: "",

    memo: "<p></p>",
    ethnobotanical_info: "<p></p>",
    generic: [],

    has_redirect: 0,
    utility_friendly: 0,
    redirect_tree: [],
    redirect_tree_id: 0,

    photolocations: "",
    images: [],

    has_fragrance: [],

    pi_invasive: [],
    ca_invasive: [],
    invasive_text: "",

    landscape_application: [],
    landscape_use: [],

    usda_zone: [],
    sunset_zone: [],
    climate_region: [],
    zip_code: [],

    climate_adapted: [],
    adapted_zip_code: [],

    height_high: "",
    height_low: "",
    width_high: "",
    width_low: "",
    growth_rate_high: "",
    growth_rate_low: "",

    exposure_low: "",
    exposure_high: "",
    soil_ph_low: "",
    soil_ph_high: "",
    shade_tolerant: "",
    soil_texture: [],

    california_native: "",

    tree_shape: [],
    litter_type: [],

    bark_texture: [],
    bark_color: 0,

    foliage_growth_color: [],
    foliage_fall_color: 0,
    foliage_type: 0,

    leaf_form: 0,
    leaf_arrangement: 0,
    leaflet_shape: 0,

    flower_color: [],
    flower_time: [],
    flower_fragrance: 0,
    flower_showiness: 0,
    sex: 0,

    fruit_type: [],
    fruiting_time: [],
    fruit_value: [],
    fruit_color: 0,
    fruit_size: 0,

    branch_strength_high: 0,
    branch_strength_low: 0,

    fragrance: [],
    water_use: [],
    salt_tolerance: [],

    native_range: "",

    planting_area: 0,
    utility_precautions: [],
    searchTerm: "",

    //Considerations
    pest_resistant: [],
    pest_susceptibility: [],
    disease_resistant: [],
    disease_susceptibility: [],

    root_damage_potential: [],
    health_hazard: [],

    deer_palatable: "",
    biogenic_emissions: [],
    attracts_wildlife: [],
    utility_friendly: [],
    schoolyard: [],
    appraisal: [],
    app_lcant: [],
    app_grrating: [],
    schoolyardUseNotes: "",
  };
};

//Months
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const Cone_Trees = [
  "Araucariaceae",
  "Cupressaceae",
  "Cycadaceae",
  "Pinaceae",
  "Podocarpaceae",
  "Sciadopityaceae",
  "Taxaceae",
];
