import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { setRegion } from "../../actions/setFilters";

import FormFieldSingleCheckbox from "../FormField/FormFieldSingleCheckbox";
import FormFieldText from "../FormField/FormFieldText";
import FormFieldMultiText from "../FormField/FormFieldMultiText";

import { optionsReturnForForm } from "../../utilities/searchChar";

class Considerations extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  optionsFor = field => {
    try {
      if (
        this.props.searchCharacteristics &&
        this.props.searchCharacteristics[field]
      ) {
        return optionsReturnForForm(this.props.searchCharacteristics[field]);
      } else {
        return [];
      }
    } catch (err) {
      console.log(`components.TreeFormCard.Considerations.optionsFor: ${err}`);
    }
  };

  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--flowers-and-fruit">
        <Row>
          <Col lg={{ span: 6 }}>
            <FormFieldMultiText
              title="Pest Resistant"
              field="pest_resistant"
              options={this.optionsFor("pestResistant")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldMultiText
              title="Pest Susceptible"
              field="pest_susceptibility"
              options={this.optionsFor("pestSusceptibility")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldMultiText
              title="Disease Resistant"
              field="disease_resistant"
              options={this.optionsFor("diseaseResistant")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldMultiText
              title="Disease Susceptible"
              field="disease_susceptibility"
              options={this.optionsFor("diseaseSusceptibility")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldText
              title="Root Damage Potential"
              field="root_damage_potential"
              options={[
                { label: "High", value: "High" },
                { label: "Moderate", value: "Moderate" },
                { label: "Low", value: "Low" },
              ]}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldMultiText
              title="Health Hazards"
              field="health_hazard"
              options={this.optionsFor("healthHazards")}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={6}>
            <FormFieldMultiText
              title="Attracts Wildlife"
              field="attracts_wildlife"
              options={this.optionsFor("attractsWildlife")}
              parentComponent={this.props.parentComponent}
            />
          </Col>

          <Col>
            <Row>
              <Col lg={6}>
                <FormFieldSingleCheckbox
                  title="Biogenic Emissions?"
                  field="biogenic_emissions"
                  options={[
                    { label: "High", value: "High" },
                    { label: "Moderate", value: "Moderate" },
                    { label: "Low", value: "Low" },
                  ]}
                  parentComponent={this.props.parentComponent}
                />
              </Col>

              <Col lg={6}>
                <FormFieldSingleCheckbox
                  title="Deer Palatable?"
                  field="deer_palatable"
                  options={[
                    { label: "No", value: "0" },
                    { label: "Yes", value: "1" },
                  ]}
                  parentComponent={this.props.parentComponent}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "0" }} lg={6}>
                <Col>
                  <FormFieldSingleCheckbox
                    title="Has Appraisal?"
                    field="appraisal"
                    options={[
                      { label: "No", value: "No" },
                      { label: "Yes", value: "Yes" },
                    ]}
                    parentComponent={this.props.parentComponent}
                  />
                </Col>
                {parentComponent.state.appraisal === "Yes" ? (
                  <Col>
                    <FormFieldText
                      title="LCANT"
                      field="app_lcant"
                      options={[
                        { label: '24" box', value: `24" box` },
                        { label: "brown trunk ft.", value: "brown trunk ft." },
                      ]}
                      parentComponent={parentComponent}
                    />

                    <FormFieldText
                      title="Group Rating"
                      field="app_grrating"
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                        { label: "3", value: "3" },
                        { label: "4", value: "4" },
                        { label: "—", value: "—" },
                        { label: "$/TF", value: "$/TF" },
                      ]}
                      parentComponent={parentComponent}
                    />
                  </Col>
                ) : (
                  ""
                )}
              </Col>

              <Col lg={6}>
                <FormFieldSingleCheckbox
                  title="Utility Friendly"
                  field="utility_friendly"
                  options={[
                    { label: "No", value: "0" },
                    { label: "Yes", value: "1" },
                  ]}
                  required={true}
                  parentComponent={parentComponent}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchCharacteristics: state.searchCharacteristics.data,
    region: state.region,
  };
};
export default connect(mapStateToProps, { setRegion })(Considerations);
