import { DEFAULT_TREE_FIELDS } from "./constants";
// map data taken from the database to the state
export const mapCallToState = data => {
  let returnData = DEFAULT_TREE_FIELDS();

  if (data) {
    returnData.tree_id = data.treeId;
    returnData.display = ["Draft", "Published"][data.generalInfo.display];
    returnData.common_name = data.commonName;
    returnData.other_common_name = data.otherCommonNames;
    returnData.other_displayed_common_name = data.otherDisplayedNameRecords;

    returnData.family = data.familyName;
    returnData.memo = data.generalInfo.memo;
    returnData.ethnobotanical_info = data.generalInfo.ethnobotanical_info;
    returnData.generic = data.genericCommonNames;

    //--------
    //TAXON
    //--------
    returnData.taxons = data.taxons;
    returnData.images = data.images;
    returnData.photolocations = data.generalInfo.photolocations;
    returnData.pi_invasive = parseIntegerBoolean(
      data.generalInfo.potentialInvasiveness.pi_invasive
    );
    returnData.ca_invasive = parseToString(
      data.generalInfo.potentialInvasiveness.ca_invasive
    );
    returnData.invasive_text =
      data.generalInfo.potentialInvasiveness.invasive_text;
    returnData.redirect = data.redirectTree.redirectTreeId;
    returnData.has_redirect = data.redirectTree.redirectTreeId ? "1" : "0";
    returnData.landscape_application = data.generalInfo.landscapeApplication;
    returnData.landscape_use = data.generalInfo.landscapeUse;
    returnData.fragrance = parseIntegerBoolean(data.generalInfo.fragrance);
    returnData.soil_ph_low = parseToString(data.siteConditions.soil_ph_low);
    returnData.soil_ph_high = parseToString(data.siteConditions.soil_ph_high);
    returnData.sunset_zone = data.siteConditions.sunsetZonesList;
    returnData.usda_zone = data.siteConditions.usdaZones;
    returnData.native_range = data.generalInfo.native_range;
    returnData.pacific_island = parseToString(data.generalInfo.pacific_island);
    returnData.california_native = parseIntegerBoolean(
      data.generalInfo.california_native
    );
    returnData.soil_texture = data.siteConditions.soilTextures;
    returnData.planting_area = data.siteConditions.planting_area;
    returnData.water_use = data.siteConditions.waterUse;
    returnData.salt_tolerance = data.siteConditions.saltTolerance;
    returnData.schoolyard = parseToString(data.siteConditions.schoolyard);
    returnData.schoolyardUseNotes = data.generalInfo.schoolyardUseNotes;
    returnData.climate_adapted_regions =
      data.siteConditions.climate_adapted_regions;
    //-------------------
    //TREE CHARACTERISTICS
    //-------------------

    returnData.shade_tolerant = data.siteConditions.shade_tolerant;
    returnData.height_low = data.treeCharacteristics.height.height_low;
    returnData.height_high = data.treeCharacteristics.height.height_high;
    returnData.width_low = data.treeCharacteristics.width.width_low;
    returnData.width_high = data.treeCharacteristics.width.width_high;
    returnData.growth_rate_low =
      data.treeCharacteristics.growthRate.growth_rate_low;
    returnData.growth_rate_high =
      data.treeCharacteristics.growthRate.growth_rate_high;
    returnData.tree_shape = data.treeCharacteristics.treeShape;
    returnData.litter_type = data.treeCharacteristics.litterType;
    returnData.fruit_size = data.treeCharacteristics.fruits.fruit_size;
    returnData.fruit_color = data.treeCharacteristics.fruits.fruit_color;
    returnData.fruit_type = data.treeCharacteristics.fruits.fruit_type;
    returnData.fruit_value = data.treeCharacteristics.fruits.fruit_value || [];
    returnData.fruiting_time = data.treeCharacteristics.fruits.fruiting_time;
    returnData.bark_color = data.treeCharacteristics.bark.bark_color;
    returnData.bark_texture = data.treeCharacteristics.bark.bark_texture;
    returnData.branch_strength_low =
      data.considerations.branchStrength.branch_strength_low;
    returnData.branch_strength_high =
      data.considerations.branchStrength.branch_strength_high;
    returnData.leaf_arrangement =
      data.treeCharacteristics.leafForms.leaf_arrangement;
    returnData.leaf_form = data.treeCharacteristics.leafForms.leaf_form;
    returnData.leaflet_shape = data.treeCharacteristics.leafForms.leaflet_shape;
    returnData.foliage_fall_color = parseIntegerBoolean(
      data.treeCharacteristics.leafForms.foliage_fall_color
    );
    returnData.foliage_type = data.treeCharacteristics.foliage_type;
    returnData.foliage_growth_color =
      data.treeCharacteristics.leafForms.foliage_growth_color;
    returnData.sex = data.treeCharacteristics.flowerSpecs.plain_sex;
    returnData.flower_showiness =
      data.treeCharacteristics.flowerSpecs.flower_showiness;
    returnData.flower_fragrance =
      data.treeCharacteristics.flowerSpecs.fragrance;
    returnData.flower_color = data.treeCharacteristics.flowerSpecs.flower_color;
    returnData.flower_time = data.treeCharacteristics.flowerSpecs.flower_time;

    //-------------------
    //Considerations
    //-------------------
    returnData.pest_resistant = data.considerations.pestResistant;
    returnData.pest_susceptibility = data.considerations.pestSusceptibility;
    returnData.disease_resistant = data.considerations.diseaseResistant;
    returnData.disease_susceptibility =
      data.considerations.diseaseSusceptibility;
    returnData.root_damage_potential = data.considerations.rootDamagePotential;
    returnData.health_hazard = data.considerations.healthHazards;
    returnData.deer_palatable = parseToString(
      data.considerations.deerPalatable
    );
    returnData.biogenic_emissions = data.considerations.biogenicEmissions;
    returnData.attracts_wildlife = data.considerations.attractsWildlife;
    returnData.utility_friendly = parseIntegerBoolean(
      data.considerations.utility_friendly
    );
    returnData.appraisal = parseToString(data.considerations.appraisal);
    returnData.app_lcant = parseToString(data.considerations.app_lcant);
    returnData.app_grrating = parseToString(data.considerations.app_grrating);
    returnData.utility_friendly = parseToString(
      data.considerations.utility_friendly
    );
  }

  return returnData;
};

const parseIntegerBoolean = value => {
  return value === null ? "" : ["No", "Yes"][value];
};

const parseToString = value => {
  return value === null ? "" : value.toString();
};
