import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import "./SearchByCharCard.css";
import FormFieldCheckbox from "../FormField/FormFieldCheckbox";
import FormFieldText from "../FormField/FormFieldText";
import FormFieldRange from "../FormField/FormFieldRange";
import { optionsReturn } from "../../utilities/searchChar";
import FormFieldMultiSelectText from "../FormField/FormFieldMultiSelectText";
import { setRegion } from "../../actions/setFilters";
import { toast } from "react-toastify";
class TreeChar extends React.Component {
  constructor(props) {
    super();
    this.state = {
      treeShapes: [],
      leafForms: [],
      leafArrangements: [],
    };
  }

  componentDidMount() {
    try {
      let region = "";
      if (sessionStorage.ufeiRegion) {
        region = sessionStorage.ufeiRegion;
      }
      this.props.setRegion(region);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.TreeChar.componentDidMount: ${err}`
      );
    }
  }

  checkmarkCA() {
    return (
      <FormFieldCheckbox
        title="Is CA Native?"
        field="ca_native"
        options={[{ label: "Yes", value: 1 }]}
        parentComponent={this.props.parentComponent}
      />
    );
  }

  checkmarkHI() {
    return (
      <FormFieldCheckbox
        title="Is Hawaii Native?"
        field="hi_native"
        options={[{ label: "Yes", value: 1 }]}
        parentComponent={this.props.parentComponent}
      />
    );
  }

  renderNativeCheckmark = () => {
    try {
      if (this.props.region) {
        switch (this.props.region) {
          case "pacific islands":
            return this.checkmarkHI();
          default:
            return this.checkmarkCA();
        }
      } else {
        return this.checkmarkCA();
      }
    } catch (err) {
      console.log(`components.SearchByCharCard.TreeChar.renderBanner: ${err}`);
      return this.checkmarkCA();
    }
  };

  componentDidUpdate = prevProps => {
    try {
      // Being even MORE defensive. Never assume the existence of data
      if (this.props.searchCharacteristics) {
        // Being defensive on props
        if (prevProps !== this.props) {
          const { treeShapes, leafForms, leafArrangements } =
            this.props.searchCharacteristics;
          this.setState({
            treeShapes: treeShapes,
            leafForms: leafForms,
            leafArrangements: leafArrangements,
          });
        }
      }
    } catch (err) {
      console.log(`components.SearchByCharCard.TreeChar: ${err}`);
    }
  };

  optionsTreeShapes = () => {
    try {
      return optionsReturn(this.state.treeShapes);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.TreeChar.optionsTreeShapes: ${err}`
      );
    }
  };

  optionsLeafForms = () => {
    try {
      return optionsReturn(this.state.leafForms);
    } catch (err) {
      console.log(
        `components.SearchByCharCard.TreeChar.optionsLeafForms: ${err}`
      );
    }
  };

  renderFoliageType = () => {
    try {
      let label = [];
      if (
        this.props.parentComponent.state.fall_color &&
        this.props.parentComponent.state.fall_color.length > 0
      ) {
        // if box is checked, prop will state ["1"];
        label = [{ label: "Deciduous", value: 2 }];
      } else {
        label = [
          { label: "Evergreen", value: 1 },
          { label: "Deciduous", value: 2 },
        ];
      }
      return label;
    } catch (err) {
      console.log(
        `components.SearchByCharCard.TreeChar.renderFoliageType: ${err}`
      );
      return null;
    }
  };

  render() {
    let parentComponent = this.props.parentComponent;
    return (
      <Container className="search-by-char-card--tree-char">
        <Row>
          <Col lg={{ span: 6 }}>
            <FormFieldRange
              title="Maximum Tree Height"
              minField="minTreeHeight"
              maxField="maxTreeHeight"
              options={{
                0: "0",
                25: "25",
                50: "50",
                75: "75",
                100: "100+",
              }}
              suffix={"ft"}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 2 }}>
            <FormFieldCheckbox
              title="schoolyard tree?"
              field="schoolyard"
              options={[{ label: "Yes", value: "1" }]}
              parentComponent={this.props.parentComponent}
            />
          </Col>
          <Col lg={{ span: 2 }}>{this.renderNativeCheckmark()}</Col>
          <Col lg={{ span: 2 }}>
            {this.props.parentComponent.state.foliage_type === "Evergreen" ||
            this.props.region === "pacific islands" ? (
              ""
            ) : (
              <FormFieldCheckbox
                title="Has Fall Color?"
                field="fall_color"
                options={[{ label: "Yes", value: "1" }]}
                parentComponent={parentComponent}
              />
            )}
          </Col>

          <Col lg={{ span: 2, offset: 6 }}>
            <FormFieldCheckbox
              title="Utility Friendly?"
              field="utility_friendly"
              options={[{ label: "Yes", value: "1" }]}
              parentComponent={this.props.parentComponent}
            />
          </Col>
          <Col lg={{ span: 2 }}></Col>

          <Col lg={{ span: 6 }}>
            <FormFieldText
              title="Tree Shape"
              field="tree_shape"
              options={this.optionsTreeShapes()}
              parentComponent={parentComponent}
            />

            <FormFieldText
              title="Foliage Type"
              field="foliage_type"
              options={this.renderFoliageType()}
              parentComponent={parentComponent}
            />
          </Col>

          <Col lg={{ span: 6 }}>
            <FormFieldText
              title="Leaf Form"
              field="leaf_form"
              options={[
                { label: "Simple", value: 1 },
                { label: "Trifoliate", value: 2 },
                { label: "Palmately Compound", value: 3 },
                { label: "Pinnately Compound", value: 4 },
                { label: "Bipinnately Compound", value: 5 },
              ]}
              parentComponent={parentComponent}
            />

            <FormFieldText
              title="Leaf Arrangement"
              field="leaf_arrangement"
              options={[
                { label: "Alternate", value: 1 },
                { label: "Alternate/Whorled", value: 2 },
                { label: "Opposite", value: 3 },
                { label: "Opposite/Whorled", value: 4 },
                { label: "Whorled", value: 5 },
              ]}
              parentComponent={parentComponent}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchCharacteristics: state.searchCharacteristics.data,
    region: state.region,
  };
};
export default connect(mapStateToProps, { setRegion })(TreeChar);
